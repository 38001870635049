import { Box } from '@mui/system'
import React from 'react'
import { DataTable, TableHeader, TextInput } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useEffect } from 'react';
import { useState } from 'react';

const Categorise = ({nav}) => {
 
  const page = () => {
    nav('add-category')
  }

  const [data , setData] = useState([])
  const [search , setSearch] = useState('')
  
    const getData = async () => {
      try {
        await API.get(`/admin-categorise/`, Vals?.GET_HEADER)
        .then(function (response) {
          if (response?.data?.status === true) {
            setData(response?.data?.data)
          } else {
            alert(response?.data?.msg)
          }
        })
      } catch (error) {
          alert(error)
      }
    } 

    const searchData = async () => {
      try {
        await API.get(`/search-category/${search}`, Vals?.GET_HEADER)
        .then(function (response) {
          if (response?.data?.status === true) {
            setData(response?.data?.data)
          } else {
            alert(response?.data?.msg)
          }
        })
      } catch (error) {
          alert(error)
      }
    } 

    

  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text'}} > {v.category_title} </TableCell> 
        <TableCell sx={{color:'primary.text'}} > {v.blogsCount} </TableCell> 
        <TableCell sx={{color:'primary.text'}} > 
          <IconButton sx={{color:"primary.text"}} aria-label="Edit" onClick={() => nav(`/edit-category/${v.category_id}`)}>
            <ModeEditTwoToneIcon />
          </IconButton>
        </TableCell> 
        {/* <TableCell sx={{color:'primary.text'}} > 
          <IconButton sx={{color:"primary.text"}} aria-label="Delete">
            <DeleteTwoToneIcon />
          </IconButton>
        </TableCell>  */}
      </TableRow>
    )))
  }

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  }, [search?.length])
 
  const Header = [
    { title : 'Name' } ,
    { title : 'Blogs' } ,
    { title : 'EDIT' } ,
    // { title : 'DELETE' } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:'Categorise' , buttonTitle : 'Add' , buttonIcon : <AddIcon /> , page : page }} />
      <TextInput {...{placeholder:'Search',set:setSearch,value:search}}/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default Categorise