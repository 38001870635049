 
 
import { IconButton, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback } from 'react'
import { TableHeader } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';

const AddCategory = ({nav}) => {

  const [ image , setImage ] = useState(null)
  const [ title , setTitle ] = useState('')
  
  const Style = {
    SearchInput : {
      input : {color:'primary.text' , },
      'textarea' : {color:'primary.text' , },
      borderWidth:1,
      borderColor:'red',
      backgroundColor:'primary.main' , 
      '&:hover' : {  backgroundColor : 'primary.main'},
      color : 'white' ,
      width:'100%' ,
    }
  }

  const getfile = (e) => {
    setImage(e)
  }

  const handleCreate64 = useCallback(async (e) => {
    const file = e.target.files[0]
    const base64 = await ToBase64(file)
    setImage(base64)
    // setUpload(file)
    e.target.value = ''
  },[])

  const ToBase64 = (file) => {
    return new Promise( (resolve,reject) => {
      const fileReader = new FileReader()
      if (file) {
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result)
        }
      }

      fileReader.onerror = (error) => {
        reject(error)
      }

    })
  }

  const deleteImg = () => {
    setImage('')
  }
 
  const page = () => {
    alert('ss')
  }

  const add = async () => {
    try {
      await API.post(`/add-category/`, {title : title} , Vals?.POST_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          nav('/categorise/')
        } else {
          alert(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  } 

  return (
    <Box p={2}>
      
      <TableHeader {...{ title:'Add Category' , buttonTitle : 'Add' , buttonIcon : <AddIcon /> , page : add }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
          
          <TextField
            onChange={(e) => setTitle(e.target.value)} 
            value={title} 
            InputLabelProps={{style:{ color: 'white' }}} 
            sx={Style?.SearchInput} 
            label="Title" 
            type="text" 
            variant="outlined" 
            autoComplete='off' />

          {/* <TextField  InputLabelProps={{style:{ color: 'white' }}} sx={[Style?.SearchInput , { marginTop:1 }]} rows={10} label="Description" multiline={true} type="text" variant="outlined" autoComplete='off'/> */}
        
        </Box>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>

        {/* <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' , flexDirection:'column'}}>

          { image ? 

          <>
            <img style={{width:270}} src={image} /> 
            <IconButton onClick={deleteImg} color="white" sx={{mt:2,backgroundColor:'primary.gold','&:hover' : { backgroundColor:'primary.gold' }}}  >
              <DeleteIcon />
            </IconButton>        
          </>

          : 
          
          <>
            <ImageIcon sx={{fontSize:350,color:'primary.main'}} /> 
            <IconButton color="white" sx={{backgroundColor:'primary.gold','&:hover' : { backgroundColor:'primary.gold' }}} aria-label="upload picture" component="label">
              <input  onChange={handleCreate64 }  hidden accept="image/*" type="file" />
              <PhotoCamera />
            </IconButton>
          </>
          
          }


        </Box> */}


        </Box>

      </Box>

    </Box>
  )
}

export default AddCategory