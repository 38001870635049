
export const Style = {
    
    Container : {
        paddingRight : 5 ,
        paddingLeft : 5 ,
        paddingTop : 2
    } ,

    tag : {
        float:'left',
        borderStyle:'solid',
        borderWidth:1,
        p:1,
        borderRadius:2,
        m:1 ,
        cursor:'pointer'
    } ,
 
    Row : { 
        borderColor:'red' , 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        } ,
        "& td": { 
            borderColor:'primary.main'
        }

    } ,

    SearchInput : {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderColor:'red',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        color : 'white' ,
        width:'100%' ,
    } ,

    Cover : {
        width:'100%',
        border:'1px solid gray',
        height:250,
        borderRadius:1,
        mt:2,
        mb:2,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'center',
    }


}

