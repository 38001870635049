import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { DataTable, TableHeader, TextInput } from '../../components'
import { Style } from './Style'
import { IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';
import BlockIcon from '@mui/icons-material/Block';
import AlertsDialog from '../../components/alertsDialog/AlertsDialog';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';

const Affiliates = ({nav}) => {
 const [data, setData] = useState([])
 const [search, setSearch] = useState('')
 const [alerts, setAlerts] = useState(false)
 const [alertsText, setAlertsText] = useState('')
 const [AlertsFunc, setAlertsFunc] = useState('')
 const [ban, setBan] = useState(0)
 
  const getData = async () => {
    try {
      await API.get(`/affiliate-users/`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          alert(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  } 

  const searchData = async () => {
    try {
      await API.get(`/search-affiliate-users/${search}`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          alert(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  } 

  useEffect(() => {
    if(search?.length>0) {
      searchData()
    } else {
      getData()
    }
  } , [search])
 
  const Header = [
    { title : 'Name' } ,
    { title : 'Signed Users' } ,
    { title : 'Verified' } ,
    { title : 'Paypal' } ,
    { title : 'Block' } ,
  ]

  const blockUser = async() => {
    try {
      await API.post(`/block-user/`, { id : ban } , Vals?.POST_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          const newData = data?.filter((i) => i.user_id != ban )
          setData(newData)
          setAlerts(false)
        } else {
          alert(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  }

  const verifyUser = async() => {
    const check = data?.find((i) => i.user_id == ban)?.user_verified
    if (check === 'n') {
      await API.post(`/user-verify/`, { id : ban } , Vals?.POST_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          const Updated = data.map((item) => {
            return {
              ...item,
              user_verified: 'y'
            };
          });
          setData(Updated)
          setAlerts(false)
        } else {
          alert(response?.data?.msg)
        }
      })
    } else {
      await API.post(`/user-unverify/`, { id : ban } , Vals?.POST_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          const Updated = data.map((item) => {
            return {
              ...item,
              user_verified: 'n'
            };
          });
          setData(Updated)
          setAlerts(false)
        } else {
          alert(response?.data?.msg)
        }
      })
    }
  }

  const BanAlert = (id) => {
    setBan(id)
    setAlertsText('Are you sure you want to ban this user !')
    setAlerts(true)
    setAlertsFunc('ban')
  }

  const verifyAlert = (id) => {
    setBan(id)
    const check = data?.find((i) => i.user_id == id)?.user_verified
    setAlertsText( check === 'n' ? 'Are you sure you want to "Verify" this user !' : 'Are you sure you want to "Unverify" this user !')
    setAlerts(true)
    setAlertsFunc('verify')
  }

  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text'}} > {v.user_username} </TableCell> 
        <TableCell sx={{color:'primary.text'}} > {v.signedUsers} </TableCell> 
        <TableCell sx={{color:'primary.text'}} onClick={() => verifyAlert(v.user_id)} > 
         <VerifiedRoundedIcon sx={{color:v.user_verified === 'y' ? 'primary.DarkGreen' : 'primary.text'}} />  
        </TableCell> 
        <TableCell sx={{color:'primary.text'}} > {v.user_paypal_account?v.user_paypal_account:'Not found'} </TableCell> 
        <TableCell sx={{color:'primary.text'}} > 
          <IconButton sx={{color:"primary.text"}} aria-label="block" onClick={() => BanAlert(v.user_id)}>
              <BlockIcon />
          </IconButton>
        </TableCell> 
      </TableRow>
    )))
  }

  return (
    <Box sx={Style?.Container}>
      <AlertsDialog {...{
          showAlert:alerts,
          setShowAlert:setAlerts,
          alertText:alertsText,
          alertFunc : AlertsFunc === 'ban' ? blockUser : verifyUser }}
      />
      <TableHeader {...{ title:'Affiliate Users' ,  }} />
      <TextInput {...{placeholder:'Search',set:setSearch,value:search}}/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default Affiliates