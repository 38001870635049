import { Grid, IconButton, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { useData } from '../../config/Provider'
import { Empty, PostCard } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system'

export default function Home({nav}) {

  const [ data , setData ] = useState([])
  const [ page , setPage ] = useState(0)
  const [check , setCheck] = useState(true)

  document.title='SYPHEX | Home'
  
  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
      await API.get( `admin-posts/${page}`, Vals?.GET_HEADER )
      .then( ( response ) => {
        if (page == 0) {
            setData(response?.data?.data);
        } else {
          if ( response?.data?.data?.length == 0 ) {
            setCheck(false)
          } else {
            setCheck(true)
            setData((prevData) => [...prevData, ...response?.data?.data]);
          }
        }
      })
      .catch((e) => console.log(e) )

  };

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const documentHeight = document.documentElement.scrollHeight;
  
    if ( scrollY + windowHeight >= documentHeight  ) {
      setPage((prevPage) => check ? prevPage + 10 : page );
    }

  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  }, [check]);  


  return (
    <Box  sx={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }}>
        <Box sx={{
            width:{
                xs:'100%',
                md:'50%' 
            }
        }}>
          
        {data?.length > 0 ? data?.map((post, key) => <PostCard key={key} {...{nav, post}} />) : <Empty {...{text:'No posts found'}} />} 

        </Box>
      <Box sx={{height: 100}} />
    </Box>
  );
}