import { ThemeProvider } from "@mui/material";
import React ,{ createContext, useState, useContext, useEffect } from "react";
import { Light , Dark } from "../style/ThemeStyle";
import { useLocalState } from "./functions";
import axios from "axios";
axios.defaults.withCredentials = true


export const Prv = createContext();

export const useData = () => {
    return useContext(Prv);
}
 
const Provider = ({children}) => {
    
    const [ theme , setTheme ] = useState(false);
    const [ logged , setlogged ] = useState(false);

    const [ savedtheme , setSavedtheme ] = useLocalState("theme");
    const [ Islogged , setIslogged ] = useLocalState("Islogged" );

    useEffect(() => {
        setTheme(savedtheme)
        setlogged(Islogged)
    } , [])

    return (
        <Prv.Provider value={{
                theme : theme,  
                setTheme : setTheme , 
                logged : logged, 
                setlogged : setlogged ,
            }} >
                <ThemeProvider theme={theme?Dark:Dark}>
            {children}
            </ThemeProvider>
        </Prv.Provider>
    );

}

export default Provider;