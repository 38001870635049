import { React, useState  }  from 'react'
import { LeftbarItems } from './LeftbarItems';
import { List, ListItemIcon, ListItemText, ListSubheader,ListItemButton,Collapse, CardMedia, Box,Button } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { Style } from './Style';
import { Logout } from '@mui/icons-material';
import { API, useLocalState } from '../../config/functions';
import Vals from '../../config/Vars';
import { useData } from '../../config/Provider';
import axios from 'axios';

const SideBar = ({toggleMobile,toggleMobileClose}) => {
  const [selectedIndex, setSelectedIndex] = useState("")
  const {setlogged} = useData()
  const handleClick = index => {
    if (selectedIndex === index) {
      setSelectedIndex("")
    } else {
      setSelectedIndex(index)
    }
  }

  const navigate = useNavigate();
  const [ Islogged , setIslogged ] = useLocalState("Islogged");

  const logout = async () => {

    try {

        await API.post(`/admin-logout/`, Vals?.POST_HEADER)

        .then(function (response) {

          if (response?.data?.status === 'ok') {
              setlogged(false)
              setIslogged(false)
              navigate('login')
          } else {
              alert(response?.data?.msg)
          }
       
        })
  
    } catch (error) {

        alert(error)

    }

}

 
 
  return (

      <Box variant='outlined' sx={Style?.Container}>
 
        <CardMedia 
            component="img"
            image={`${Vals?.M_API}/logo512.png`}
            alt="logo"
            sx={Style?.img}
        />

        <Box sx={{display:  {xs : 'flex',lg:'none'} , justifyContent: 'center'}}>
          <Button variant="contained" onClick={toggleMobileClose}>
            <CloseIcon />
          </Button>
        </Box>
      
        { LeftbarItems.map((val, mainKey) => {

          return  (
            <List key={mainKey} 
            sx={{ 
            width: '100%',
            height:'auto',  
             }} 
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={

                <ListSubheader sx={{ 
                    backgroundColor:'primary.main',
                    fontWeight:'bold',
                    fontSize:'12px',
                    color:'primary.text'
                  }}
                
             component="div" id="nested-list-subheader" >

                    {val.title}

                </ListSubheader>


            }>
               

              { val.list.map((listVal, midKey) => { 

                return (
                  
                  <div key={midKey}>
               
                    <ListItemButton onClick={() =>  
                                      listVal.dropdown.length > 0 ? 
                                        handleClick(midKey) 
                                      : 
                                      listVal?.title !== 'Logout' ? navigate(listVal.link) : logout() }  sx={Style?.ListItem}>
                        
                        <ListItemIcon sx={Style?.ItemColor} >

                          {listVal.icon}

                        </ListItemIcon>
                      
                        <ListItemText  primary={listVal.title} sx={{color:'primary.text'}}/>
 
                        {  listVal.dropdown.length > 0 ? midKey === selectedIndex ? <ExpandLess sx={{color:'primary.text'}} /> : <ExpandMore sx={{color:'primary.text'}} />  : '' }

                    </ListItemButton>
                  
                    <Collapse in={midKey === selectedIndex}    timeout="auto"  unmountOnExit>
              
                        { listVal.dropdown.map((dropVal, lastKey) =>  (

                                  <List  component="div" disablePadding key={lastKey}>

                                    <ListItemButton sx={{ pl: 4  }}  
                                    onClick={()=>  navigate(dropVal.link)} >

                                      <ListItemText   primary={dropVal.title}  sx={{color:'primary.text'}} />

                                    </ListItemButton>

                                  </List>
                                         
                            ) 

                      )}   

                    </Collapse>                     

                    </div>
                )

              })}
                   
            </List>
          )   

        })}
 
      </Box>
  
 
    
  )
}

export default SideBar