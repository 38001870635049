export const Style = {
    container: {
      padding: 3,
      borderWidth:1,
      borderRadius: 0,
      borderStyle:'solid',
      height:'auto' ,
      backgroundColor:'primary.main',
      borderColor:'primary.border'
    },
    header: {
        display:'flex' ,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent:'space-between' ,
    },
    profilePicture: {
      width: 32,
      height: 32,
      borderRadius: 16,
      marginRight: 8,
      objectFit :'cover',

    },
    author: {
      fontSize: 14,
      cursor:'pointer'
    },
    title: {
      fontSize: 19,
      fontWeight: 'bold',
    //   marginTop: 8,
      textAlign:'left'

    },
    image: {
      width: '100%',
      height: 400,
    //   marginTop: 8,
    },
    description: {
      fontSize: 19,
    //   lineHeight:35,
      marginTop: 2,
      textAlign:'left'
    },
    footer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent:'space-between',
      display : 'flex' , 
      marginTop: 2,
    },
    score: {
      fontSize: 14,
      marginHorizontal: 8,
    },
  }