import React, { useContext, useEffect, useState } from 'react';
import { Style } from './Style';
import { Avatar, Box, Card, CardMedia, IconButton, Typography } from '@mui/material';
import { useData } from '../../config/Provider';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { API , CalculateTime } from '../../config/functions';
import Vals from '../../config/Vars'
import { CommentsList, Slider, TextInput } from '../../components';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { useParams } from 'react-router-dom';

const Post = ({nav}) => {
    const [ upVoted , srtUpVoted ] = useState(false) 
    const [ downVoted , srtDownVoted ] = useState(false) 
    const [ seeMore , setSeeMore ] = useState(false) 
    const [ deleted , setDelete ] = useState(false) 
    const [ load , setLoad ] = useState(true) 
    const [ count , srtCount ] = useState(0) 
    const [ media , setMedia ] = useState([]) 
    const [ post , setPost ] = useState([]) 
    const [ commentss ,setComments  ] = useState([]) 
    const [ comment ,setComment  ] = useState('') 
    const [ commentCount ,setCommentCount  ] = useState(0) 
    
    const { Color , user , setNotifyNum } = useData()

    const { id } = useParams()

    const getData = async () => {
        await API.get(`/admin-post/${id}` , Vals?.GET_HEADER )
        .then( ( response ) => {
            setPost( response?.data?.post )
            srtCount(response?.data?.post?.votes?response?.data?.post?.votes:0)
            document.title=`SYPHEX | ${response?.data?.post?.post_title}` 
            setLoad(false)
        })
        .catch((e) => console.log(e) )

        await API.get(`/guest-get-post-comments/${id}` , Vals?.GET_HEADER )
        .then( ( response ) => {
            setComments( response?.data?.data )
            
        })
        .catch((e) => console.log(e) )
    }

    const DeleteAlert = () => {
        //   Alert.alert(
        //     'Delete',
        //     'Do you want to delete this post?',
        //     [
        //       { Typography: 'Cancel', onClick: () => console.log('Cancel pressed'), style: 'cancel' },
        //       { Typography: 'OK', onClick:Delete },
        //     ],
        //   )
      }
 
    useEffect(() => {
        getData()
        setCommentCount(post?.comments_count)
    } , [id])

    const deleteComment = async (id) => {
      return false
      await API.post( `/delete-comment-web/`,{id:id} , Vals?.POST_HEADER )
      .then( ( response ) => {
        if (response?.data?.status === true) {
          const filter = commentss?.filter((i) => i.comment_id != id)
          setComments(filter)
        } else {
        //   Alert.alert('Error','Somthing went wrong !!')
        }
      })
      .catch((e) => console.log(e))
    }
    
    const des = post?.post_description?.length > 100 ? `${post?.post_description.substring(0,seeMore?64:post?.post_description?.length)} ${seeMore? '...see more' : '' }` : post?.post_description
    const profilePic = post?.user_profile_pic ? `${Vals?.LOCALHOST}/profile-pictures/${post?.user_profile_pic}` :`${Vals?.M_API}/logo.png` 
  
  if (!load)
  return (
    <Box  sx={{
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
  }}>
      <Box sx={{
          width:{
              xs:'100%',
              md:'50%' 
          }
      }}>
        
    <Card sx={Style?.container }>
    <Box sx={Style?.header}>
      <Box sx={{ display:'flex' , flexDirection:'row',justifyContent:'flex-start',alignItems:'center' , backgroundColor:'primary.black'}}>
        <Avatar src={profilePic} style={Style?.profilePicture} alt='lounxadnan' />
        <Typography sx={[Style?.author,{color:'primary.text'}]}    > u/{post?.user_username} -</Typography>
        <Typography ml={1} sx={[Style?.author,{color:'primary.text'}]} > d/@{post?.discussion_title}</Typography>
      </Box>

      <Box sx={{ display:'flex' ,justifyContent:'flex-end',flexDirection:'row' , backgroundColor:'primary.main'  }}>
    
        {/* <IconButton  sx={{flexDirection:'row' , alignItems:'center'}} onClick={DeleteAlert}>
          <DeleteIcon sx={{color:'primary.text',fontSize:18}} />
        </IconButton> */}

      </Box>
    </Box>

    <Box sx={{ height:20 }} />
      <Typography sx={[Style?.title , {color:'primary.text'}]}>
        {post?.post_title}
      </Typography>
      <Typography sx={[Style?.description , {color:'primary.text'}]} onClick={() => setSeeMore(false)} >
        {des}
      </Typography>
    <Box>
      <Slider {...{media:post?.post_media}}/>
    </Box>
    <Box sx={{ height:20 }} />
  
    <Typography sx={{color:'primary.text' , fontSize:13 , }}>{CalculateTime(post?.post_date)}</Typography>
     
    <Box sx={[Style?.footer , { backgroundColor:'primary.black' }]}>
      <Box sx={{flexDirection:'row' , alignItems:'center' , display : 'flex' , backgroundColor:Color?.main}}>
        
        <IconButton  >
           <ExpandMoreSharpIcon sx={{color:'primary.text'}} />
        </IconButton>
        
        <Typography sx={[Style?.score , { color:'primary.text' }]}>{count}</Typography>

        <IconButton >
           <ExpandLessSharpIcon sx={{color:'primary.text'}} />
        </IconButton>
      </Box>
      
      <IconButton  sx={{ display : 'flex', flexDirection:'row' , alignItems:'center'  }} >
        <Typography sx={{marginHorizontal:10,color:'primary.text',mr:1}}>{post?.comments_count }</Typography>
        <ForumOutlinedIcon sx={{color:'primary.text'}} />
      </IconButton>
      
    </Box>
 
    <CommentsList comments={commentss} nav={nav} deleteComment={deleteComment} />
  </Card>
  
  </Box>
  </Box>
  
  
  )
}

export default Post