import HomeRoundedIcon from '@mui/icons-material/HomeRounded'; 
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import CategoryIcon from '@mui/icons-material/Category';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import PolicyTwoToneIcon from '@mui/icons-material/PolicyTwoTone';
import GavelTwoToneIcon from '@mui/icons-material/GavelTwoTone';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import OfflineShareTwoToneIcon from '@mui/icons-material/OfflineShareTwoTone';
import AddTaskIcon from '@mui/icons-material/AddTask';

export const LeftbarItems =  [

    {

        title : 'CONTROL PANEL',
        
        list : [
            
            {
                title : 'Overview',
                dropdown : [],
                icon : <HomeRoundedIcon sx={{color:'common'}} />,
                link : '/home/',
            },

            {
                title : 'Categories',
                icon : <CategoryIcon  />,
                dropdown : [
                    {
                        title : 'List',
                        link : '/categorise/'
                    },
                    {
                        title : 'Create',
                        link : '/add-category/'
                    },
                  
                ]
            },

            {
                title : 'Posts',
                icon : <NewspaperIcon  />,
                dropdown : [ ],
                link : '/posts/',
            },

            {
                title : 'Affiliates',
                icon : <OfflineShareTwoToneIcon  />,
                dropdown : [ ],
                link : '/affiliates/',
            },

            {
                title : 'Affiliate Terms',
                icon : <AddTaskIcon  />,
                dropdown : [ ],
                link : '/affiliate-terms/',
            },

            {
                title : 'Users',
                icon : <PeopleAltOutlinedIcon  />,
                dropdown : [

                    {
                        title : 'Active',
                        link : '/users'
                    },

                    {
                        title : 'Banned',
                        link : '/banned-users/'
                    }

                ]
            },

            {
                title : 'Blogs',
                icon : <LibraryBooksOutlinedIcon  />,
                dropdown : [

                    {
                        title : 'List',
                        link : '/blogs/'
                    },

                    {
                        title : 'Add new',
                        link : '/add-blog/'
                    },
       
                ]
            },

            {
                title : 'Terms & Conditions',
                dropdown : [],
                icon : <PolicyTwoToneIcon />,
                link : '/terms-and-conditions',
            },

            {
                title : 'Privacy Policy',
                dropdown : [],
                icon : <GavelTwoToneIcon />,
                link : '/privacy-policy',
            },

            {
                title : 'Logout',
                dropdown : [],
                icon : <LoginRoundedIcon />,
                link : '',
            },

        ],
    },

  
]

 