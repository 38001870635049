import React, { useEffect, useState } from 'react'
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,} from 'chart.js';
import { Line } from "react-chartjs-2";
import { Box, Button } from '@mui/material';

ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend);
 
const MultiLine = ({info}) =>  {
  const [timeInterval, setTimeInterval] = useState('months')
  const [ Filtered , setFiltered] = useState([])

  const MData = []

  const filterData = () => {

    if (timeInterval === 'months') {
      setFiltered({
        comments : info?.commentsMonths ,
        discussions : info?.discussionsMonths ,
        posts : info?.postsMonths ,
        users : info?.usersMonths
      })
    }

    if (timeInterval === 'years') {
      setFiltered({
        comments : info?.commentsYears ,
        discussions : info?.discussionsYears ,
        posts : info?.postYears,
        users : info?.usersYears
      })
    }

    if (timeInterval === 'weeks') {
      setFiltered({
        comments : info?.commentsWeek ,
        discussions : info?.discussionsWeek ,
        posts : info?.postsWeek ,
        users : info?.usersWeek
      })
    }

    console.log(Filtered)

  }

  useEffect(() => {
    filterData()
  } , [timeInterval])

  const labelSets = {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    years: [
      2023,
      2024,
      2025,
      2026,
    ],
    weeks: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ],
  };
    const data = {
        
        labels:  labelSets[timeInterval],
        datasets: [
          {
            label : 'Users',
            data: Filtered?.users?.map((v) => v.value ),
            backgroundColor: '#869ee4',
            borderColor:'#869ee4'
          },
          {
            label :'Discussions',
            data: Filtered?.discussions?.map((v) => v.value ),
            backgroundColor: '#3a539d',
            borderColor:'#3a539d'
          },
          {
            label : 'Posts',
            data: Filtered?.posts?.map((v) => v.value ),
            backgroundColor: '#37817f',
            borderColor:'#37817f'
          },
          {
            label : 'Comments',
            data: Filtered?.comments?.map((v) => v.value ),
            backgroundColor: '#87649e',
            borderColor:'#87649e'
          },
        ]
      };
 
      const options = {
        responsive: true,
        
        plugins: {
            legend: { display: true },
        },
 
        scales: { 
            
            y: {
                ticks: {display: true,},
                grid: {display: true,drawBorder: true},
            }, 
            
            x: {
                ticks: {display: true},
                grid: {display: true,drawBorder: true}
            } 
        } 

    };

    const Style = {
      Btn : {
        backgroundColor:'primary.main',
        color:'primary.text',
        borderWidth:1,
        borderStyle:'solid'
      }
    }

  return (
    <Box sx={{width:'100%'}}>
      
      <Line data={data} options={options} />
      <Box sx={{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
      }}>
        
        <Button sx={[Style?.Btn,{borderColor:timeInterval==='months'?'primary.themeColor':'primary.border'}]} onClick={() => setTimeInterval('months')}>Months</Button>
        <Button sx={[Style?.Btn,{borderColor:timeInterval==='years'?'primary.themeColor':'primary.border'}]} onClick={() => setTimeInterval('years')}>Years</Button>
        <Button sx={[Style?.Btn,{borderColor:timeInterval==='weeks'?'primary.themeColor':'primary.border'}]} onClick={() => setTimeInterval('weeks')}>Days</Button>
      </Box>
  </Box>
  )
}

export default MultiLine