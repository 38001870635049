import React, { useState } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import Router from './Router';
import { SideBar } from '../components';
import { useData } from './Provider';
import { Login } from '../pages';
import Vals from './Vars';
import { useEffect } from 'react';
import { API } from './functions';
import { useNavigate } from 'react-router-dom';
 
const Main = () => {

    const { logged , setlogged } = useData()
    const [loading , setLoading ] = useState(true)
    const nav = useNavigate() 

    const check = async () => {

        try {

            await API.get(`/admin-check/`, Vals?.GET_HEADER)
    
            .then(function (response) {

                if (response.data.status === 'n') {
                    setlogged(false) 
                    nav('/login/')
                } 
        
                if (response.data.status === 'y') {
                    setlogged(true) 
                } 

                setLoading(false)

            })
      
        } catch (error) {

            alert(error)

        }

    }

    useEffect(() => {
        check()
    } , [])

    if (logged && !loading) {
        return (

            <Grid container sx={{  flexDirection:'row' , width:'100%' , backgroundColor:'primary.secondary'}}>

                <Grid item={true} sx={{width:{ xs:'0%',sm:'0%',md:'0%',lg:'15%',xl:'15%'}}} >
                    <SideBar />
                </Grid>

                <Grid item={true} sx={{ minHeight: '100vh' , height:'100%' , width: { xs:'100%',sm:'100%',md:'85%',lg:'85%',xl:'85%' } }}  >
                    <Router />
                </Grid>

            </Grid>
        )

    }

    if (!logged && !loading) {
        return( <Router />)
    }
  
}

export default Main;