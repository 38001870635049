import { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import Vals from '../../config/Vars';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

const Style = {
  root: {
    margin: '10px',
    backgroundColor:'primary.main'
  },
  avatar: {
    marginRight: '10px',
  },
};

function Comment({ comment , nav , deleteComment }) {

  const [ upVoted , srtUpVoted ] = useState(false) 
  const [ downVoted , srtDownVoted ] = useState(false) 
  const [ count , setCount] = useState(comment?.votes?comment?.votes:0);
  const [ deleted , setDelete ] = useState(false) 
  const {user} = useData()
 
  const profilePic = comment?.user_profile_pic ? `${Vals?.LOCALHOST}/profile-pictures/${comment?.user_profile_pic}` :`${Vals?.M_API}/logo.png` 

  return (
    <Card sx={Style?.root}>
      <CardHeader 
       
        avatar={
          <Avatar sx={Style?.avatar} src={profilePic} />
        }
        title={`@${comment?.user_username}`}
        subheader={<Typography sx={{  color:'primary.text',fontSize:12 }}> {comment?.comment_date === 'Now' ? 'Now' : new Date(comment?.comment_date).toLocaleString()} </Typography>}
        sx={{color:'primary.text' ,  cursor:'pointer'}}
      />
      <CardContent>
        <Typography color='primary.text'>{comment?.comment_text}</Typography>
      </CardContent>
      <Box sx={{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center' ,
      }} >
         <Box sx={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center' ,
         }}>
         <IconButton aria-label="upvote" >
            <ExpandMoreSharpIcon   sx={{color:'primary.text'}}/>
          </IconButton>
          <Typography color='primary.text'>{count}</Typography>
          <IconButton aria-label="downvote" >
            <ExpandLessSharpIcon  sx={{color:'primary.text'}}/>
          </IconButton>     
         </Box>
          {/* <IconButton aria-label="downvote" onClick={() => deleteComment( comment?.comment_id )}>
            <DeleteTwoToneIcon  sx={{color:'primary.text'}}/>
          </IconButton> */}
      </Box>
    </Card>
  );
}

function CommentsList({ comments , nav , deleteComment }) {
  return (
    <>
      {comments?.map((comment,key) => (
        <Comment key={key} comment={comment} nav={nav} deleteComment={deleteComment} />
      ))}
    </>
  );
}

export default CommentsList;