import React from 'react'
import { Style } from './Style'
import { Table , TableHead , TableCell , TableBody , TableRow } from '@mui/material';

const DataTable = (props) => {
  const {Header , Data} = props
  return (
    <Table style={Style?.Table}>
      <TableHead>
        <TableRow sx={Style?.TableRow}>
          { Header?.map((v,k) => ( <TableCell key={k} sx={{fontWeight:'bold' , color:'primary.text'}} > {v.title} </TableCell> )) }
        </TableRow>
      </TableHead>
      <TableBody>
        {Data}
      </TableBody>
    </Table>
  )
  
}

export default DataTable