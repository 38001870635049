import { Box } from '@mui/system'
import React from 'react'
import { DataTable, TableHeader, TextInput } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useEffect } from 'react';
import { useState } from 'react';
import AlertsDialog from '../../components/alertsDialog/AlertsDialog';

const Blogs = ({nav}) => {
 
  const page = () => {
    nav('add-blog')
  }

  const [data , setData] = useState([])
  const [search , setSearch] = useState('')
  const [alerts, setAlerts] = useState(false)
  const [id, setId] = useState(0)

  const DeleteAlert = (id) => {
    setId(id)
    setAlerts(true)
  }

  const getData = async () => {
    try {
      await API.get(`/admin-blogs/`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          alert(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  } 

  const searchData = async () => {
    try {
      await API.get(`/admin-search-blogs/${search}`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          alert(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  } 

  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text'}} > {v.blog_title} </TableCell> 
        <TableCell sx={{color:'primary.text'}} > 
          <IconButton sx={{color:"primary.text"}} aria-label="Edit" onClick={() => nav(`/edit-blog/${v.blog_id}`)}>
            <ModeEditTwoToneIcon />
          </IconButton>
        </TableCell> 
        <TableCell sx={{color:'primary.text'}} > 
          <IconButton sx={{color:"primary.text"}} aria-label="Delete" onClick={() => DeleteAlert(v.blog_id)} >
            <DeleteTwoToneIcon />
          </IconButton>
        </TableCell> 
      </TableRow>
    )))
  }

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  }, [search?.length])
 
  const Header = [
    { title : 'Blog' } ,
    { title : 'EDIT' } ,
    { title : 'DELETE' } ,
  ]


  const Delete = async () => {
    try {
      await API.post(`/delete-blog/`, { id : id } , Vals?.POST_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          const filter = data?.filter((i) => i.blog_id != id)
          setData(filter)
          setAlerts(false)
        } else {
          console.log(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
    
  }

  return (
    <Box sx={Style?.Container}>
      <AlertsDialog {...{
          showAlert:alerts,
          setShowAlert:setAlerts,
          alertText:'Are you sure you want to delete this blog !',
          alertFunc : Delete }}
      />
      <TableHeader {...{ title:'Blogs' , buttonTitle : 'Add' , buttonIcon : <AddIcon /> , page : page }} />
      <TextInput {...{placeholder:'Search',set:setSearch,value:search}}/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default Blogs