
import { createTheme } from '@mui/material/styles';
 

export const Dark = createTheme({
    palette: {
      primary: {
        main: '#101010',
        secondary : '#0a0a0a',
        text:'white',
        themeColor : '#3a546e' ,
        DarkGreen : '#7191b7',
        border: '#3a3a3a'
      },
    },
});

export const Light = createTheme({
    palette: {
        primary: {
        main: '#101010',
        secondary : '#0a0a0a',
        text:'white',
        themeColor : '#3a546e' ,
        DarkGreen : '#7191b7',
        border: '#3a3a3a'
        },
    },
});
    