import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { DataTable, TableHeader, TextInput } from '../../components'
import { Style } from './Style'
import { IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';
import BlockIcon from '@mui/icons-material/Block';
import AlertsDialog from '../../components/alertsDialog/AlertsDialog';

const Users = ({nav}) => {
 const [data, setData] = useState([])
 const [search, setSearch] = useState('')
 const [alerts, setAlerts] = useState(false)
 const [ban, setBan] = useState(0)

  const getData = async () => {
    try {
      await API.get(`/blocked-users/`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          alert(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  } 

  const searchData = async () => {
    try {
      await API.get(`/search-blocked-users/${search}`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          alert(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  } 

  useEffect(() => {
    if(search?.length>0) {
      searchData()
    } else {
      getData()
    }
  } , [search])
 
  const Header = [
    { title : 'Name' } ,
    { title : 'Unblock' } ,
  ]

  const unblockUser = async() => {
    try {
      await API.post(`/unblock-user/`, { id : ban } , Vals?.POST_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          const newData = data?.filter((i) => i.user_id != ban )
          setData(newData)
          setAlerts(false)
        } else {
          alert(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  }

  const BanAlert = (id) => {
    setBan(id)
    setAlerts(true)
  }

  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text'}} > {v.user_username} </TableCell> 
        <TableCell sx={{color:'primary.text'}} > 
          <IconButton sx={{color:"primary.text"}} aria-label="block" onClick={() => BanAlert(v.user_id)}>
              <BlockIcon />
          </IconButton>
        </TableCell> 
      </TableRow>
    )))
  }

  return (
    <Box sx={Style?.Container}>
      <AlertsDialog {...{
          showAlert:alerts,
          setShowAlert:setAlerts,
          alertText:'Are you sure you want to unban this user !',
          alertFunc : unblockUser }}
      />
      <TableHeader {...{ title:'Banned Users' ,  }} />
      <TextInput {...{placeholder:'Search',set:setSearch,value:search}}/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default Users