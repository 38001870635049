import axios from 'axios';
import moment from 'moment/moment';
import { useState,useEffect, useCallback } from 'react'
axios.defaults.withCredentials = true

// this function for saving values into browser local storage
export const useLocalState = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue === null ? defaultValue : JSON.parse(storedValue);
    });
  
    useEffect(() => {
      const listener = (e) => {
        if (e.storageArea === localStorage && e.key === key) {
          setValue(JSON.parse(e.newValue));
        }
      };
      window.addEventListener("storage", listener);
  
      return () => {
        window.removeEventListener("storage", listener);
      };
    }, [key]);
  
    const setValueInLocalStorage = (newValue) => {
      setValue((currentValue) => {
        const result =
          typeof newValue === "function" ? newValue(currentValue) : newValue;
        localStorage.setItem(key, JSON.stringify(result));
        return result;
      });
    };
  
    return [value, setValueInLocalStorage];
};

export const API = axios.create({
  baseURL: 'https://api.syphex.com',
  // baseURL: 'http://localhost:3000',
  timeout: 60000 , 
});

export const CalculateTime = (datetimeValue) => {
  const elapsedTime = moment().diff(moment(datetimeValue), 'seconds');
  var v = ''
  if (elapsedTime < 60) {
      var v = `${elapsedTime} seconds ago`
  } else if (elapsedTime < 3600) {
      var v =`${Math.floor(elapsedTime / 60)} minutes ago`
  } else if (elapsedTime < 86400) {
      var v =`${Math.floor(elapsedTime / 3600)} hours ago`
  } else if (elapsedTime < 604800 ) {
      var v =`${(Math.floor(elapsedTime / 86400) > 1 ? Math.floor(elapsedTime / 86400)+' days ago' :  `1 day ago`)}`
  } else if (elapsedTime < 2419200 ) {
      var v =`${(Math.floor(elapsedTime / 604800) > 1 ? Math.floor(elapsedTime / 604800) + ' Months ago' : `1 Month ago`)}`
  } else if (elapsedTime < 29030400 ) {
      var v =`${(Math.floor(elapsedTime / 2419200) > 1 ? Math.floor(elapsedTime / 2419200) + ' years ago' : '1 year ago')}`
  }
  return v
}

export const HandleCreate64ForState = (setStateFunction, format) => {
  return useCallback(async (e) => {
      const file = e.target.files[0]
      const base64 = await ToBase64(file)
      const blob = dataURItoBlob(base64)
      setStateFunction({blob:blob,base64:base64})
      e.target.value = ''
  },[setStateFunction])
}

export const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export const ToBase64 = (file) => {
  return new Promise( (resolve,reject) => {
    const fileReader = new FileReader()
    if (file) {
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
    }

    fileReader.onerror = (error) => {
      reject(error)
    }

  })
}