export const Style = {
    Container : {
        backgroundColor:'primary.main',
        top:0,
        bottom:0,
        height:'100%',
        width:'15%',
        display : 'flex' ,
        flexDirection : 'column' ,
        justifyContent : 'flex-start' ,
        position: { xs:'fixed',  lg:'fixed'},
        zIndex:1,
        overflow: 'scroll'
    } ,
    img : {
        width:150,
        height:150,
        mb:5,
        borderRadius:50,
        mr:'auto',
        ml:'auto',
        mt:10
    } ,
    ListItem : {
        color:'primary.text' ,
        backgroundColor : 'primary.secondary' ,
        borderRadius : 5 , 
        marginTop : 1 ,
        marginRight : 1 ,
        marginLeft : 1 ,

    } ,
    ItemColor : {
        color:'primary.text' ,
    }
}