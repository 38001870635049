import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { TableHeader, TextEditor, TextInput } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { Grid , IconButton , Typography } from '@mui/material';
import { useState } from 'react';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useCallback } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { API, HandleCreate64ForState } from '../../config/functions';
import Vals from '../../config/Vars';
import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';

const EditBlog = ({nav}) => {
 
  const [ image , setImage ] = useState(null)
  const [data, setData] = useState([])
  const [blog, setBlog] = useState([])
  const [selected, setSelected] = useState([])
  const [ contnet , setcontnet ] = useState('')
  const [ title , setTitle ] = useState('')
  const { id } = useParams()
  const [ cover , setCover ] = useState(null)

  const getData = async () => {
    try {
      await API.get(`/admin-categorise/`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          alert(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  } 

  const getBlogData = async () => {
    try {
      await API.get(`/get-blog/${id}`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          setTitle(response?.data?.data[0]?.blog_title)
          setcontnet(response?.data?.data[0]?.blog_description)
          setSelected(response?.data?.categorise)
          if (response?.data?.data[0]?.blog_cover) {
            setCover({base64:`${Vals?.LOCALHOST}/blogs/${response?.data?.data[0]?.blog_cover}`})
          }
          if (response?.data?.data[0]?.blog_image) {
            setImage({base64:`${Vals?.LOCALHOST}/blogs/${response?.data?.data[0]?.blog_image}`})
          }
        } else {
          console.log(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  } 

  useEffect(() => {
    getData()
    getBlogData()
  }, [])

  const page = async () => {

    var formdata = new FormData()

    if (image?.blob) {
      formdata.append('image', image?.blob, 'image');
    } else if (image?.base64 && !image?.blob) {
      formdata.append('image', 'same' );
    } else {
      formdata.append('image', '' );
    }

    if (cover?.blob) {
      formdata.append('cover', cover?.blob, 'cover');
    } else if (cover?.base64 && !cover?.blob) {
      formdata.append('cover', 'same' );
    } else {
      formdata.append('cover', '');
    }

    formdata.append("content",contnet);
    formdata.append("title",title);
    formdata.append("selected",JSON.stringify(selected));
    formdata.append("blog_id", id );

    try {
      await API.post(`/save-blog/`, formdata ,  Vals?.POST_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          nav('/blogs/')
        } else {
          console.log(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  }

  const addCategory = (category_id) => {
    if (selected?.find((i) => i.category_id == category_id)) {
      const filter = selected?.filter((v) => v.category_id != category_id) 
      setSelected(filter)
    } else {
      setSelected([...selected  , {
        category_id : category_id
      }])
    }
  }

  const imageIcon = HandleCreate64ForState(setImage)
  const imageCover = HandleCreate64ForState(setCover)

  return (
    <Box sx={Style?.Container}>

      <Grid container>

        <TableHeader {...{ title:'Edit Blog' , buttonTitle : 'save' , buttonIcon : <CheckIcon /> , page : page }} />

        <Grid item={true} xs={12} sm={12} md={9} lg={9} xl={9}>
          <TextInput {...{placeholder:'Title',set:setTitle,value:title}} />
          <Box sx={[Style?.Cover,{backgroundImage : `url(${cover?.base64})`}]}>
            {cover ? 
            <IconButton onClick={()=>setCover(null)} color="white" sx={{mt:2,backgroundColor:'primary.themeColor','&:hover' : { backgroundColor:'primary.themeColor' }}}  >
              <DeleteIcon />
            </IconButton>        
          : 
          <>
            <ImageIcon sx={{fontSize:250,color:'primary.main'}} /> 
            <IconButton color="white" sx={{backgroundColor:'primary.themeColor','&:hover' : { backgroundColor:'primary.themeColor' }}} aria-label="upload picture" component="label">
              <input  onChange={imageCover}  hidden accept="image/*" type="file" />
              <PhotoCamera sx={{color:'primary.text'}} />
            </IconButton>
          </>}
          </Box>
          <TextEditor {...{contnet:contnet , setcontnet:setcontnet }}/>
        </Grid>

        <Grid item={true} xs={12} sm={12} md={3} lg={3} xl={3}>
          <Box sx={{ 
            justifyContent:'center',display:'flex',alignItems:'center' , flexDirection:'column',mt:10
          }}>
            <Typography color='primary.text' mb={2} fontSize={20}> Select Categorise </Typography>
            <Box sx={{float:'left',width:'80%' }}>

              {data?.map((item,key) => (
                <Box sx={
                  [Style?.tag , { borderColor: selected?.find((i) => i.category_id == item?.category_id ) ? 'primary.themeColor' : 'primary.border' }]
                  } key={key} onClick={() => addCategory(item?.category_id)}>
                  <Typography color='primary.text'> {item?.category_title} </Typography>
                </Box>
              ))}
        
            </Box>

          </Box>
          <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' , flexDirection:'column'}}>

            { image ? 

            <>
              <img style={{width:270,}} src={image?.base64} /> 
              <IconButton onClick={()=>setImage(null)} color="white" sx={{mt:2,backgroundColor:'primary.themeColor','&:hover' : { backgroundColor:'primary.themeColor' }}}  >
                <DeleteIcon />
              </IconButton>        
            </>

            : 
            
            <>
              <ImageIcon sx={{fontSize:350,color:'primary.main'}} /> 
              <IconButton color="white" sx={{backgroundColor:'primary.themeColor','&:hover' : { backgroundColor:'primary.themeColor' }}} aria-label="upload picture" component="label">
                <input  onChange={imageIcon }  hidden accept="image/*" type="file" />
                <PhotoCamera sx={{color:'primary.text'}} />
              </IconButton>
            </>
            
            }

          </Box> 

        </Grid>

      </Grid>

    </Box>
  )
}

export default EditBlog