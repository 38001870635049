import { Button, CardMedia, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Style } from './Style'
import LoginIcon from '@mui/icons-material/Login';
import Vals from '../../config/Vars';
import { API, useLocalState } from '../../config/functions';
import { useState } from 'react';
import { useData } from '../../config/Provider';

const Login = ({nav}) => {
    const {logged,setlogged } = useData()
    const [ username , setusername ] = useState('')
    const [ password , setpassword ] = useState('')
    const [ Islogged , setIslogged ] = useLocalState("Islogged" , false);

    const Submit = async () => {
        await API.post( `/admin-login/` , { username : username , password : password } , Vals?.POST_HEADER )
        .then( function ( response ) {
            console.log(response?.data)
            if (response?.data?.status === 'ok') {
                setlogged(true)
                setIslogged(true)
                nav('/')
            } else {
                alert(response?.data?.msg)
                setlogged(false)
            }
        })
        .catch((e) => alert(e) )
    }

    return (
        <Box sx={Style?.Container}>

            <Box sx={Style?.FormContainer}>
            
                <CardMedia 
                    component="img"
                    image={`${Vals?.M_API}/logo512.png`}
                    alt="LOUNX logo"
                    sx={Style?.img}
                />
            
                <Box sx={Style?.Form}>
                    <TextField value={username} onChange={(e) => setusername(e.target.value)} InputLabelProps={{style:{ color: 'white' }}} sx={Style?.Input} label="username" type="username" variant="outlined" autoComplete='off'/>
                    <TextField value={password} onChange={(e) => setpassword(e.target.value)} InputLabelProps={{style:{ color: 'white' }}} sx={Style?.Input} label="Password" type="Password" variant="outlined" autoComplete='off'/>
                </Box>
            
                <Box sx={Style?.Button}>
                    <Button onClick={Submit} sx={{width:330,backgroundColor:'primary.border'}} variant="contained" endIcon={<LoginIcon />}>Login</Button>
                </Box>
            
            </Box>

        </Box>
    )
}

export default Login