import React, { useContext, useEffect, useState } from 'react';
import { Style } from './Style';
import { Avatar, Box, Card, CardMedia, IconButton, Typography } from '@mui/material';
import { useData } from '../../config/Provider';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { API , CalculateTime } from '../../config/functions';
import Vals from '../../config/Vars'
import { useParams } from 'react-router';
import Slider from '../slider/Slider';
import AdjustIcon from '@mui/icons-material/Adjust';

const PostCard = ({ nav , post }) => {
    // const context = 
    const [ upVoted , srtUpVoted ] = useState(false) 
    const [ downVoted , srtDownVoted ] = useState(false) 
    const [ seeMore , setSeeMore ] = useState(false) 
    const [ count , srtCount ] = useState(0) 
    const [ media , setMedia ] = useState([]) 

    const { Color , user} = useData()

    const des = post?.post_description?.length > 100 ? `${post?.post_description.substring(0,seeMore?64:post?.post_description?.length)} ${seeMore? '...see more' : '' }` : post?.post_description

    const comments = () => {
        nav(`/post/${post?.post_id}`)
    }

    const Delete = async () => {
      return false
      const dataBody = {
        id : post?.post_id
      }

      await API.post( `/remove-post/`,dataBody , Vals?.POST_HEADER )
      .then( ( response ) => {
        if (response?.data?.status === true) {
        } else {
          console.log('Somthing went wrong !!')
        }
      })
      .catch((e) => console.log(e) )
    }

    const profilePic = post?.user_profile_pic ? `${Vals?.LOCALHOST}/profile-pictures/${post?.user_profile_pic}` :`${Vals?.LOCALHOST}/logo.png`  
    // const profilePic = 'http://192.168.0.25:8080/profile-pictures/jRgxtS14Tj1bMvHndr24C54330A6-BFDB-44AB-A5B5-9AD163CB865D.jpg' 
    
    useEffect(() => {
      srtCount(post?.votes?post?.votes:0)
      srtUpVoted(post?.voted==='votedUp'?true:false)
      srtDownVoted(post?.voted==='votedDown'?true:false)
      setMedia(JSON.parse(post?.post_media))
    } , [] )
 
    return (
        <Card sx={Style?.container }>
          <Box sx={Style?.header}>
            <Box sx={{ display:'flex' , flexDirection:'row',justifyContent:'flex-start',alignItems:'center' , backgroundColor:'primary.black'}}>
              <Avatar src={profilePic} style={Style?.profilePicture} alt='lounxadnan' />
              <Typography sx={[Style?.author,{color:'primary.text'}]} > u/{post?.user_username} -</Typography>
              <Typography ml={1} sx={[Style?.author,{color:'primary.text'}]} > d/@{post?.discussion_title}</Typography>
            </Box>

            <Box sx={{ display:'flex' ,justifyContent:'flex-end',flexDirection:'row' , backgroundColor:'primary.main'  }}>
            {user == post?.post_user_id && ( 
              <IconButton  sx={{flexDirection:'row' , alignItems:'center'}} onClick={Delete}>
                <DeleteIcon sx={{color:'primary.text',fontSize:18}} />
              </IconButton>
            )} 

            </Box>
              <Box sx={{
                display:'flex',
                flexDirection:'row'
              }}>
                <Typography sx={{color:'primary.text'}}> Deleted :  </Typography>
                <AdjustIcon sx={{color: post?.post_delete === 'y'? 'red' : 'green' ,ml:1 }} />
              </Box>
          </Box>

          <Box sx={{ height:20, }} />

          <Typography sx={[Style?.title , {color:'primary.text',wordBreak:'break-all'}]}>
            {post?.post_title}
          </Typography>
          <Typography sx={[Style?.description , {color:'primary.text',wordBreak:'break-all'}]} onClick={() => setSeeMore(false)} >
            {des}
          </Typography>
          <Box>
            <Slider {...{media:post?.post_media}}/>
          </Box>
 
          <Box sx={{ height:20 }} />
        
          <Typography sx={{color:'primary.text' , fontSize:13 , }}>{CalculateTime(post?.post_date)}</Typography>
           
          <Box sx={[Style?.footer , { backgroundColor:'primary.black' }]}>
            <Box sx={{flexDirection:'row' , alignItems:'center' , display : 'flex' , backgroundColor:Color?.main}}>
              
              <IconButton  >
                 <ExpandMoreSharpIcon sx={{color:'primary.text'}} />
              </IconButton>
              
              <Typography sx={[Style?.score , { color:'primary.text' }]}>{count}</Typography>

              <IconButton >
                 <ExpandLessSharpIcon sx={{color:'primary.text'}} />
              </IconButton>
            </Box>
            
            <IconButton  sx={{ display : 'flex', flexDirection:'row' , alignItems:'center'  }} onClick={comments}>
              <Typography sx={{marginHorizontal:10,color:'primary.text',mr:1}}>{post?.comments_count }</Typography>
              <ForumOutlinedIcon sx={{color:'primary.text'}} />
            </IconButton>
            
          </Box>
        </Card>
      );
}



export default PostCard

 