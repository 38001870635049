import { InputBase } from '@mui/material'
import React from 'react'

const TextInput = ({value,set,placeholder,name}) => {
    const Style = {
        SearchInput : {
            input : {color:'primary.text'  },
            placeholder : {color:'primary.text'  },
            borderWidth:0.5,
            borderStyle:'solid',
            borderColor:'gray',
            p:1,
            color : 'white' ,
            width:'100%' ,
            borderRadius:1
        }
    }
  return (
    <InputBase sx={Style?.SearchInput} name={name} onChange={(e) => set(e.target.value)} value={value} placeholder={placeholder}/>
  )
}

export default TextInput