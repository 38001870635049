import axios from "axios";
import { useEffect } from "react";
import {  Navigate, Outlet, Route, Routes, useLocation, useNavigate  } from "react-router-dom";
import {   Home , Policy , Terms ,Blogs, AddBlog, Users, BannedUsers,AddCategory ,Categorise, Login, EditCategory, EditBlog, Posts, Post, Affiliates, AffiliateTerms   } from '../pages/'
import { API, useLocalState } from "./functions";
import { useData } from "./Provider";
import Vals from "./Vars";
// axios.defaults.withCredentials = true
 
const Router = () => {

    const nav = useNavigate() 
    const { logged,setlogged } = useData()
    const [ Islogged , setIslogged ] = useLocalState("Islogged" );

    const ProtectedRoutes = () => {
        const location = useLocation();
        return Islogged ? (<Outlet />) : (<Navigate to="/login" replace state={{ from: location }} />);
    };

    return   (
        <Routes>   
            <Route path="/login" element={<Login {...{nav}} />} />
            <Route element={<ProtectedRoutes />}>
                <Route path="/Home" element={<Home  {...{nav}} />} />
                <Route path="/" element={<Home {...{nav}} />} />
                <Route path="/privacy-policy" element={<Policy {...{nav}} />} />
                <Route path="/terms-and-conditions" element={<Terms {...{nav}} />} />
                <Route path="/blogs" element={<Blogs {...{nav}} />} />
                <Route path="/add-blog" element={<AddBlog {...{nav}} />} />
                <Route path="/users" element={<Users {...{nav}} />} />
                <Route path="/banned-users" element={<BannedUsers {...{nav}} />} />
                <Route path="/add-category" element={<AddCategory {...{nav}} />} />
                <Route path="/categorise" element={<Categorise {...{nav}} />} />
                <Route path="/edit-category/:id" element={<EditCategory {...{nav}} />} />
                <Route path="/edit-blog/:id" element={<EditBlog {...{nav}} />} />
                <Route path="/posts/" element={<Posts {...{nav}} />} />
                <Route path="/post/:id" element={<Post {...{nav}} />} />
                <Route path="/affiliates/" element={<Affiliates {...{nav}} />} />
                <Route path="/affiliate-terms/" element={<AffiliateTerms {...{nav}} />} />
            </Route>
        </Routes>
    )  
 
};

export default Router;