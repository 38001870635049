import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import './style.css'

const TextEditor = ({contnet , setcontnet , direction}) => {

  const  handleChange = (value) => {
    setcontnet(value);
  }
  return (
    <ReactQuill value={contnet}   onChange={handleChange} />
  )
}

export default TextEditor