import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { DataTable, TableHeader, TextEditor } from '../../components'
import { Style } from './Style'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useState } from 'react';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';

const Terms = ({nav}) => {

  const [ contnet , setcontnet ] = useState('')
 
  const page = async () => {
    try {
      await API.post(`/save-terms/`, { text : contnet } , Vals?.POST_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
           console.log('saved')
        } else {
          console.log(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  }

  const getData = async () => {
    try {
      await API.get(`/get-terms/`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          setcontnet(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
      })
    } catch (error) {
        alert(error)
    }
  } 

  useEffect(() => {
    getData()
  } , [])

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:'Terms & Conditions' , buttonTitle : 'save' , buttonIcon : <SaveOutlinedIcon /> , page : page }} />
      <TextEditor {...{contnet:contnet , setcontnet:setcontnet }}/>
    </Box>
  )
}

export default Terms