import React, { useEffect, useState } from 'react'
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { Box, Grid, Paper, Typography } from '@mui/material';
import MultiLine from '../../components/charts/lineChart/MultiLine';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';

const Home = () => {
  
  const [data, setData] = useState([])
  const [load, setLoad] = useState(true)

  const getData = async () => {
    try {
      await API.get(`/admin-home/`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
          setLoad(false)
        } else {
          console.log(response?.data?.msg)
        }
      })
    } catch (error) {
      console.log(error)
    }
  } 

  useEffect(() => {
    getData()
  } , [])

  const mitems = [
    { title : 'New Users' , icon : <GroupAddIcon sx={{fontSize:55,color:'primary.themeColor'}} /> , num : data?.newUsers } ,
    { title : 'Total Users' , icon : <GroupIcon sx={{fontSize:55,color:'primary.themeColor'}}  /> , num : data?.totalUsers } ,
    { title : 'New Discussions' , icon : <WorkspacesIcon sx={{fontSize:55,color:'primary.themeColor'}}  /> , num : data?.newDiscussions } ,
    { title : 'Total Discussions' , icon : <WorkspacesOutlinedIcon sx={{fontSize:55,color:'primary.themeColor'}}  /> , num : data?.totalDiscussions } ,
  ]

  const MitemCard = ({item}) => (
    <Grid item={true} xs={12} sm={12} md={3} lg={3} xl={3} sx={{
      display:'flex',justifyContent:'center',alignItems:'center'
    }}>
      <Paper sx={{
        width:'90%',
        p:2,
        display:'flex',
        flexDirection:'row',
        backgroundColor:'primary.main',
        alignItems:'center'
      }}>
        <Box sx={{
          width:'60%',
          display:'flex',
          flexDirection:'column',
          justifyContent:'flex-start',
        }}>
          <Typography color='primary.text' fontSize={20} >{item?.title}</Typography>
          <Typography color='primary.text' fontSize={15}>{item?.num}</Typography>
        </Box>

        <Box sx={{width:'40%',  display:'flex',
          justifyContent:'center',
          alignItems:'center'}}>
          {item?.icon}
        </Box>
      </Paper>
    </Grid>
 
  )

  if (!load)
  return (
    <Box m={2}>
      <Grid container spacing={2}>
        {mitems?.map((item,key) => <MitemCard key={key} {...{item}} /> )}

        <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper sx={{
            width:'100%',
            display:'flex',
            flexDirection:'row',
            backgroundColor:'primary.main',
            alignItems:'center',
            pt:1,
            pb:1,
          }}>
            <MultiLine info={data}/>
          </Paper>
        </Grid>
        <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
          
        </Grid>
      </Grid>
    </Box>
  )
}

export default Home